import React from 'react';
import * as Styles from '../styles';
import Box from 'components/Box';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const ABOUT_US_CARDS = [
  {
    description:
      'about_us.more_about_us.group_244.your_financial_world_in_a_cup.61',
    text: 'about_us.more_about_us.group_244.your_financial_world_in_a_cup.16',
    href: '/about',
  },
  {
    description:
      'about_us.more_about_us.group_244.your_financial_world_in_a_cup.100',
    text: 'about_us.more_about_us.group_244.your_financial_world_in_a_cup',
    href: '/careers',
  },
  {
    description:
      'about_us.more_about_us.group_244.your_financial_world_in_a_cup.69',
    text: 'about_us.more_about_us.group_244.your_financial_world_in_a_cup.3',
    href: '/insights',
  },
];

interface CardProps {
  element: {
    description: string;
    text: string;
    href: string;
  };
}

const Cards = ({ element }: CardProps) => {
  const { description, text, href } = element;
  const { t } = useTranslation();

  return (
    <div>
      <Styles.SectionCards>
        <h6>{t(description)}</h6>
        <p>{t(text)}</p>
        <Link className="know-more" to={href}>
          {t(
            'about_us.more_about_us.group_244.your_financial_world_in_a_cup.39',
          )}
        </Link>
      </Styles.SectionCards>
    </div>
  );
};

const LearnMore = () => {
  const { t } = useTranslation();
  return (
    <Styles.LearnMoreWrapper>
      <Box>
        <Styles.LearnMoreHeading>
          <h3> {t('about_us.more_about_us.title')} </h3>
        </Styles.LearnMoreHeading>
        <Styles.LearnMoreCards>
          {ABOUT_US_CARDS.map((item) => (
            <Cards element={item} key={item.text} />
          ))}
        </Styles.LearnMoreCards>
      </Box>
    </Styles.LearnMoreWrapper>
  );
};

export default LearnMore;
