import React from 'react';

import * as Styles from '../styles';
import Carousel from 'components/ProvidersCarousel';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { breakpoints } from 'theme';

const Flex = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: unset;
  }
`;

const Providers = () => {
  const { t } = useTranslation();

  return (
    <Styles.ProvidersWrapper>
      <Styles.ProvidersHeading>
        <h6>{t('about_us.partners.title')}</h6>
        <h3> {t('about_us.partners.subtitle')} </h3>
        <p>{t('about_us.partners.description')}</p>
      </Styles.ProvidersHeading>
      <Flex>
        <Carousel />
      </Flex>
    </Styles.ProvidersWrapper>
  );
};

export default Providers;
