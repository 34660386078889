import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
`;

const Images = styled.img`
  width: ${rem(248)};
  height: ${rem(120)};
`;

interface ProvidersProps {
  element: {
    icon: any;
  };
}
const ProvidersItem = React.forwardRef(({ element }, ref) => {
  const { iconUp, iconDown } = element;
  return (
    <Container ref={ref as React.RefObject<HTMLDivElement>}>
      <Images
        src={iconUp}
        alt="Providers logo"
        style={{ marginBottom: '16px' }}
      />
      <Images src={iconDown} alt="Providers logo" />
    </Container>
  );
});

export default ProvidersItem;
