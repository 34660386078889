import React from 'react';
import About from 'pageComponents/About';
import { graphql, HeadProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AboutPage = () => <About />;

export default AboutPage;

export function Head(props: HeadProps) {
  const { t } = useTranslation();
  return (
    <title>{t('home.atf.navigation_tabs.about_us')} | Safebrok</title>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
