import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';
import background from 'images/home-testimonial-bg.png';
import backgroundMobile from 'images/home-testimonial-bg-mobile.png';
import quoteMarks from 'images/home-testimonial-quotemark.png';
import borjaPhoto from 'images/borja-profile-photo.png';

const Container = styled.section`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${(props) => props.theme.neutralPalette.white};

  text-align: left;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0;
    background: url(${backgroundMobile}), #02c4b2;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const WebContent = styled.div`
  display: flex;
  padding: 56px 156px;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

const MobileContent = styled.div`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    padding: 40px 24px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 46px;
  margin-right: 110px;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 46px;
`;

const TopContent = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const TopRightContent = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TestimonialQuoteContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TestimonialQuoteText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(28)};
  line-height: ${rem(40)};

  @media (max-width: ${breakpoints.tablet}) {
    line-height: 27px;
  }
`;

const TestimonialName = styled.strong`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  margin-bottom: 8px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 0px;
  }
}
`;

const TestimonialRole = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin-bottom: 48px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 0px;
  }
`;

const TestimonialDescription = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 24px;
  }
`;

const TestimonialPhoto = styled.img`
  width: 150px;
  height: 150px;
`;

const QuoteMark = styled.img`
  height: 15px;
  width: 24px;
  margin-bottom: 14px;
  margin-top: 10px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 24px;
  }
`;

const WebTestimonial = () => {
  const { t } = useTranslation();
  return (
    <WebContent>
      <LeftContent>
        <TestimonialPhoto src={borjaPhoto} alt="Borja Prados" />
        <TestimonialQuoteContent>
          <QuoteMark src={quoteMarks} alt="" aria-hidden="true" />
          <TestimonialQuoteText>{t('about.borja_quote')}</TestimonialQuoteText>
        </TestimonialQuoteContent>
      </LeftContent>
      <RightContent>
        <TestimonialName>{t('about.borja.name')}</TestimonialName>
        <TestimonialRole>{t('about.borja.role')}</TestimonialRole>
        <TestimonialDescription>
          {t('about.borja_description')}
        </TestimonialDescription>
      </RightContent>
    </WebContent>
  );
};

const MobileTestimonial = () => {
  const { t } = useTranslation();
  return (
    <MobileContent>
      <TopContent>
        <TestimonialPhoto src={borjaPhoto} alt="Borja Prados" />
        <TopRightContent>
          <TestimonialName>{t('about.borja.name')}</TestimonialName>
          <TestimonialRole>{t('about.borja.role')}</TestimonialRole>
        </TopRightContent>
      </TopContent>

      <TestimonialDescription>
        {t('about.borja_description')}
      </TestimonialDescription>
      <TestimonialQuoteContent>
        <QuoteMark src={quoteMarks} alt="" aria-hidden="true" />
        <TestimonialQuoteText>{t('about.borja_quote')}</TestimonialQuoteText>
      </TestimonialQuoteContent>
    </MobileContent>
  );
};

const Testimonial = () => {
  return (
    <Container>
      <WebTestimonial />
      <MobileTestimonial />
    </Container>
  );
};

export default Testimonial;
