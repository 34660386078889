import React, { createRef } from 'react';

import ProvidersItem from 'components/ProvidersItem';

import { COMPANIES_PROVIDER_CARDS } from 'data/providers';
import Carousel from 'components/Carousel';

const ProvidersCarousel = () => {
  return (
    <Carousel numberOfVisibleCards={4} numberOfVisibleCardsMobile={1}>
      {COMPANIES_PROVIDER_CARDS.map((item) => (
        <ProvidersItem ref={createRef()} element={item} key={Math.random()} />
      ))}
    </Carousel>
  );
};

export default ProvidersCarousel;
