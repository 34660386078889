import React from 'react';
import * as Styles from '../styles';

import TransparencyIcon from 'images/TransparencyIcon.png';
import TrustIcon from 'images/TrustIcon.png';
import HonestyIcon from 'images/HonestyIcon.png';

import Box from 'components/Box';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const OUR_VALUES_CARDS = [
  {
    icon: TransparencyIcon,
    heading: 'about_us.values.transparency.title',
    text: 'about_us.values.transparency.subtitle',
  },
  {
    icon: TrustIcon,
    heading: 'about_us.values.trust.title',
    text: 'about_us.values.trust.subtitle',
  },
  {
    icon: HonestyIcon,
    heading: 'about_us.values.commitment.title',
    text: 'about_us.values.commitment.subtittle',
  },
];

interface CardProps {
  element: {
    icon: any;
    heading: string;
    text: string;
  };
}

const Cards = ({ element }: CardProps) => {
  const { icon, heading, text } = element;

  const { t } = useTranslation();

  return (
    <>
      <Styles.EachCard>
        <img src={icon} alt="" aria-hidden="true" />
        <h5>{t(heading)}</h5>
        <p>{t(text)}</p>
      </Styles.EachCard>
    </>
  );
};

const OurValues = () => {
  const { t } = useTranslation();

  return (
    <Styles.OurValuesWrapper>
      <Box>
        <Styles.OurValuesHeadingWrapper>
          <Styles.OurValuesHeading>
            {t('about_us.values.subtitle')}
          </Styles.OurValuesHeading>
        </Styles.OurValuesHeadingWrapper>
        <Styles.SectionTwoHeadings>
          <h6>{t('about_us.values.title')}</h6>
          <h3>{t('about_us.values.subtitle')}</h3>
        </Styles.SectionTwoHeadings>
        <Styles.CardBox>
          {OUR_VALUES_CARDS.map((item) => (
            <Cards element={item} key={item.text} />
          ))}
        </Styles.CardBox>
      </Box>
    </Styles.OurValuesWrapper>
  );
};

export default OurValues;
