import React from 'react';
import type { HeadFC } from 'gatsby';
import SectionPictures from './components/SectionPictures';
import OurValues from './components/OurValues';
import Providers from './components/Providers';
import LearnMore from './components/LearnMore';
import PageHero from 'components/PageHero';
import Testimonial from './components/Testimonial';
import { useI18next } from 'gatsby-plugin-react-i18next';

const About = () => {
  const { language } = useI18next();

  return (
    <>
      <PageHero />
      <SectionPictures />
      { (language === 'pt' || language === 'en') && <Testimonial /> }
      <OurValues />
      <Providers />
      <LearnMore />
    </>
  );
};

export default About;

export const Head: HeadFC = () => <title>About</title>;
