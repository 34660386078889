import axaicon from 'images/axaicon.svg';
import asisacon from 'images/asisaicon.svg';
import califeicon from 'images/califeicon.svg';
import libertyicon from 'images/libertyicon.svg';
import prebalicon from 'images/prebalicon.svg';
import auraicon from 'images/auraicon.svg';
import mapfreicon from 'images/mapfreicon.svg';
import realeicon from 'images/realeicon.svg';
import surneicon from 'images/surneicon.svg';
import sanitasicon from 'images/sanitasicon.svg';
import dkvicon from 'images/dkvicon.svg';
import adeslasicon from 'images/adeslasicon.svg';
import mutuaicon from 'images/mutuaicon.svg';
import whiteboxicon from 'images/whiteboxIcon.svg';

export const COMPANIES_PROVIDER_CARDS = [
  {
    iconUp: axaicon,
    iconDown: prebalicon,
  },
  {
    iconUp: asisacon,
    iconDown: auraicon,
  },
  {
    iconUp: califeicon,
    iconDown: mapfreicon,
  },
  {
    iconUp: libertyicon,
    iconDown: realeicon,
  },
  {
    iconUp: surneicon,
    iconDown: mutuaicon,
  },
  {
    iconUp: sanitasicon,
    iconDown: whiteboxicon,
  },
  {
    iconUp: dkvicon,
    iconDown: whiteboxicon,
  },
  {
    iconUp: adeslasicon,
    iconDown: whiteboxicon,
  },
];
