import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from 'theme';

import Circlesleft from 'images/Circlesleft.svg';
import Circlesright from 'images/Circlesright.svg';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(80)};
  margin-top: ${rem(80)};
  max-width: 1440px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${rem(40)};
    margin-bottom: ${rem(40)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    opacity: 0.7;
  }

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: flex-start;
    padding-bottom: ${rem(30)};
  }
`;

export const OurValuesHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: flex-start;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 96px;
  text-align: start;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    gap: 32px;
  }

  :nth-of-type(odd) {
    flex-direction: row-reverse;

    @media (max-width: ${breakpoints.tablet}) {
      flex-direction: column;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: ${rem(500)};
    max-height: ${rem(460)};

    @media (max-width: ${breakpoints.tablet}) {
      max-width: ${rem(334)};
      max-height: ${rem(294)};
    }
  }
`;

export const CardHeading = styled.div`
  gap: ${rem(16)};

  h4 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(28)};
    line-height: ${rem(40)};
    color: ${(props) => props.theme.brandPalette.dark};
    margin-bottom: ${rem(16)};

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${rem(24)};
      line-height: ${rem(27)};
    }
  }

  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.brandPalette.dark};
    opacity: 0.8;
    font-size: ${rem(20)};
    line-height: ${rem(32)};
  }
`;

export const Header = styled.h3`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.light};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(32)};
  }
`;

export const Subtitle = styled.h2`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(40)};
  margin-top: 18px;
  color: ${(props) => props.theme.brandPalette.dark};
  margin-bottom: 30px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
    margin-top: 2px;
  }
`;

export const UnderHeader = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  max-width: 1400px;
  margin-top: 15px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    text-align: left;
  }
`;

export const OurValuesHeading = styled.h2`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(20)};
  margin-top: 18px;
  color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
    margin-top: 2px;
  }
`;

export const SectionTwoHeadings = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  h6 {
    font-size: ${rem(20)};
    font-weight: ${(props) => props.theme.fontWeights.light};
    padding: 15px 0;
    color: ${(props) => props.theme.neutralPalette.white};
  }

  h3 {
    font-size: ${rem(32)};
    font-weight: ${(props) => props.theme.brandPalette.dark};
    padding-bottom: ${rem(46)};
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    color: ${(props) => props.theme.neutralPalette.white};
  }

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: flex-start;

    h6 {
      padding-bottom: ${rem(3)};
      font-size: ${rem(18)};
      line-height: ${rem(22)};
    }

    h3 {
      padding-bottom: ${rem(0)};
      font-size: ${rem(28)};
      line-height: ${rem(32)};
    }
  }
`;

export const CardBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(60)};

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }
`;

export const EachCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: ${rem(10)};
  width: ${rem(349)};
  height: ${rem(280)};
  background-color: white;
  margin: ${rem(14)};
  justify-content: center;
  align-items: center;

  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  img {
    width: ${rem(80)};
  }

  h5 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
  }

  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    color: ${(props) => props.theme.brandPalette.dark};
    text-align: center;
    padding: 0 40px 0 40px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: ${rem(327)};
    height: ${rem(250)};

    img {
      width: ${rem(49)};
      margin-top: ${rem(30)};
    }

    h5 {
      font-weight: ${(props) => props.theme.fontWeights.semibold};
      font-size: ${rem(18)};
      line-height: ${rem(22)};
      margin-top: ${rem(5)};
    }
    p {
      font-weight: ${(props) => props.theme.fontWeights.regular};
      font-size: ${rem(16)};
      line-height: ${rem(24)};
      padding: 5px 60px 20px 60px;
    }
  }
`;

export const OurValuesWrapper = styled.div`
  background-color: ${(props) => props.theme.neutralPalette.white};
`;

export const SectionCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  width: ${rem(359)};
  height: ${rem(224)};
  left: ${rem(180)};
  top: ${rem(901)};
  border-radius: ${rem(10)};
  margin: ${rem(16)};
  justify-content: center;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: ${rem(8)};
  padding: 24px 24.5px;

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    color: ${(props) => props.theme.brandPalette.dark};
  }
  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    opacity: 0.8;
  }

  .know-more {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    text-align: right;
    color: ${(props) => props.theme.brandPalette.primary};

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.brandPalette.secondary};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: ${rem(20)} ${rem(16)};
    margin-bottom: ${rem(20)};

    h6 {
      margin: ${rem(10)} 0 ${rem(10)} 0;
      font-size: ${rem(18)};
      line-height: ${rem(22)};
      align-self: flex-start;
    }

    p {
      font-size: ${rem(16)};
      line-height: ${rem(24)};
      align-self: flex-start;
    }

    .know-more {
      margin-bottom: ${rem(16)};
      margin-top: ${rem(10)};
      align-self: flex-end;
    }
  }
`;

export const LearnMoreWrapper = styled.div`
  background-color: ${(props) => props.theme.neutralPalette.white};
  padding-bottom: ${rem(30)};
`;

export const LearnMoreHeading = styled.div`
  h3 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(32)};
    line-height: ${rem(46)};
    text-align: center;
    color: ${(props) => props.theme.brandPalette.dark};
    margin-top: ${rem(30)};

    @media (max-width: ${breakpoints.tablet}) {
      text-align: left;
      align-items: flex-start;
      padding-bottom: ${rem(40)};
      padding-left: ${rem(5)};
      font-size: 28px;
      line-height: 32px;
      margin-top: ${rem(80)};
    }
  }
`;

export const LearnMoreCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 80px 180px;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin: 10px 25px 0 0;
  }
`;
export const ProvidersHeading = styled.div`
  text-align: center;
  padding-top: ${rem(80)};

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: flex-start;
    padding-bottom: ${rem(30)};
    padding-left: ${rem(19)};
  }

  h3 {
    font-weight: ${(props) => props.theme.fontWeights.semibold};
    font-size: ${rem(32)};
    line-height: ${rem(46)};
    color: ${(props) => props.theme.brandPalette.dark};
    margin-bottom: ${rem(5)};
  }

  h6 {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    color: ${(props) => props.theme.neutralPalette.neutral6};
    margin-bottom: ${rem(5)};
  }

  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-size: ${rem(20)};
    line-height: ${rem(32)};
    opacity: 0.8;
    color: ${(props) => props.theme.brandPalette.dark};
    padding: 32px 180px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 14px;
    margin-bottom: ${rem(40)};
    padding-top: ${rem(80)};

    h3 {
      font-size: ${rem(28)};
      line-height: ${rem(32)};
      margin-top: ${rem(2)};
    }

    h6 {
      font-size: ${rem(18)};
      line-height: ${rem(22)};
    }

    p {
      padding: 40px 0;
    }
  }
`;

export const ProvidersWrapper = styled.div`
  background: ${(props) => props.theme.brandPalette.light};
  background-image: url(${Circlesleft}), url(${Circlesright});
  background-repeat: no-repeat;
  background-position: top left, bottom right;

  @media (max-width: ${breakpoints.tablet}) {
    background: ${(props) => props.theme.brandPalette.light};
    background-image: url(${Circlesleft});
    background-repeat: no-repeat;
    background-position: 100% 75%;
  }
`;

export const ProvidersGrid = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;
