import React from 'react';
import * as Styles from '../styles';

import AboutFirst from 'images/aboutfirst.svg';
import AboutSecond from 'images/aboutsecond.svg';
import AboutThird from 'images/aboutthird.svg';

import Box from 'components/Box';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ABOUT_IMAGES_DESCRIPTION = [
  {
    icon: AboutFirst,
    heading: 'about_us.main_blocks.1.title',
    text: 'about_us.main_blocks.1.description',
  },
  {
    icon: AboutSecond,
    heading: 'about_us.main_blocks.2.title',
    text: 'about_us.main_blocks.2.description',
  },
  {
    icon: AboutThird,
    heading: 'about_us.main_blocks.3.subtitle',
    text: 'about_us.main_blocks.3.description',
  },
];

interface ImagesProps {
  element: {
    icon: any;
    heading: string;
    text: string;
  };
}

const Images = ({ element }: ImagesProps) => {
  const { icon, heading, text } = element;

  const { t } = useTranslation();

  return (
    <Styles.CardContainer>
      <img src={icon} alt="" aria-hidden="true" />
      <Styles.CardHeading>
        <h4>{t(heading)}</h4>
        <p>{t(text)}</p>
      </Styles.CardHeading>
    </Styles.CardContainer>
  );
};

const SectionPictures = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Styles.ContentWrapper>
        <Styles.Header> {t('about_us.main_blocks.title')} </Styles.Header>
        <Styles.Subtitle>
          {t('about_us.main_blocks.subtitle')}
        </Styles.Subtitle>
        <Styles.UnderHeader> {t('about.subtitle_under_header')} </Styles.UnderHeader>
        <Styles.Flex>
          {ABOUT_IMAGES_DESCRIPTION.map((item) => (
            <Images element={item} key={item.text} />
          ))}
        </Styles.Flex>
      </Styles.ContentWrapper>
    </Box>
  );
};

export default SectionPictures;
